.meus-dados {
    form {
        display: flex;
        flex-direction: column;
        width: 40%;
        max-width: 750px;
        min-width: 530px;
        margin-left: auto;
        margin-right: auto;

        .btn {
            width: 120px;
            margin-left: auto;
        }

        .title {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            margin-left: -15px;
            padding-left: 15px;
            margin-right: -15px;
            margin-bottom: 15px;
            padding-bottom: 10px;
            font-size: 18px;

            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        .input-group {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            label {
                font-size: 15px;
                opacity: .8;
                margin-bottom: 10px;
            }

            input, select {
                width: 400px;
                margin-left: auto;
            }
        }

        @media screen and (max-width: 680px) {
            & {
                width: 100%;
                min-width: unset;
                max-width: unset;

                .input-group {
                    flex-direction: column;
                    align-items: flex-start;

                    input, select {
                        margin-left: 0;
                        width: 100%;
                    }

                    .input-container {
                        width: 100%;
                    }
                }
            }
        }
    }
}
